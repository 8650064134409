import { EventBase } from "@core/events";
import { UserAccountInfoDto } from "@swagger/humanresources";

export class GetUserAccountInfoResult extends EventBase {
    constructor(public readonly payload: UserAccountInfoDto) {
        super('UserService.GetUserAccountInfoResult');
    }
}
 
export class QueryUserAccountInfosResult extends EventBase {
    constructor(public readonly payload: UserAccountInfoDto[]) {
        super('UserService.QueryUserAccountInfosResult');
    }
}
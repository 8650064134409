import { inject } from '@angular/core';
import { EventQueue } from './event-queue';
import { EventBase, EventCtor } from './event';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

type EventFn<T extends EventBase> = (event: T) => void;
type EventListener = { unsubscribe: () => void };

export function event(type: string, fn: EventFn<EventBase>): EventListener;
export function event(types: string[], fn: EventFn<EventBase>): EventListener;
export function event<T extends EventBase>(
  type: EventCtor<T>,
  fn: EventFn<T>
): EventListener;
export function event<T1 extends EventBase, T2 extends EventBase>(
  types: [EventCtor<T1>, EventCtor<T2>],
  fn: EventFn<T1 | T2>
): EventListener;
export function event<T1 extends EventBase, T2 extends EventBase, T3 extends EventBase>(
  types: [EventCtor<T1>, EventCtor<T2>, EventCtor<T3>],
  fn: EventFn<T1 | T2 | T3>
): EventListener;
export function event<
  T1 extends EventBase,
  T2 extends EventBase,
  T3 extends EventBase,
  T4 extends EventBase
>(
  types: [EventCtor<T1>, EventCtor<T2>, EventCtor<T3>, EventCtor<T4>],
  fn: EventFn<T1 | T2 | T3 | T4>
): EventListener;
export function event(
  types: (string | EventCtor<EventBase>)[],
  fn: EventFn<EventBase>
): EventListener;
export function event(type: any, fn: EventFn<EventBase>): EventListener {
  const eventQueue = inject(EventQueue);

  const sub = eventQueue
    .on(type as any)
    .pipe(takeUntilDestroyed())
    .subscribe((event) => fn(event));

  return { unsubscribe: () => sub.unsubscribe() };
}

import { Injectable, inject } from '@angular/core';
import {
  FetchSettingGroupsGQL,
  FetchValueMetadataGQL,
  FetchSettingGQL,
  FetchAllSettingsGQL,
  FetchSingleSettingGroupGQL,
  FetchSingleValueMetadataGQL,
  FetchSettingByGroupUIdAndKeyGQL,
} from '@graphql/hrp-settings';
import { Actions, OnInitEffects, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import {
  fetchSetting,
  fetchSettingByGroupUIdAndKey,
  fetchSettingGroup,
  fetchSettingGroups,
  fetchSettings,
  fetchSingleValueMetadata,
  fetchValueMetadata,
  initKeyValueSetting,
} from './key-value-setting.actions';
import {
  catchError,
  debounceTime,
  map,
  mergeMap,
  of,
  switchMap,
  throttleTime,
} from 'rxjs';

@Injectable()
export class KeyValueSettingEffects implements OnInitEffects {
  private actions$ = inject(Actions);

  initKeyValueSetting$ = createEffect(() =>
    this.actions$.pipe(
      ofType(initKeyValueSetting),
      mergeMap(() =>
        of(
          fetchSettingGroups.request(),
          fetchSettings.request(),
          fetchValueMetadata.request()
        )
      )
    )
  );

  fetchSettingGroupsGQL = inject(FetchSettingGroupsGQL);

  fetchSettingGroups$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchSettingGroups.request),
      debounceTime(1000),
      switchMap(() =>
        this.fetchSettingGroupsGQL.fetch(undefined , { fetchPolicy: 'no-cache' }).pipe(
          map((response) =>
            fetchSettingGroups.success({
              settingGroups: response.data.settingGroups,
            })
          ),
          catchError((error) => [fetchSettingGroups.failure({ error })])
        )
      )
    )
  );

  fetchSettingGroupByUIdGQL = inject(FetchSingleSettingGroupGQL);

  fetchSettingGroupByUId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchSettingGroup.request),
      mergeMap(({ uId }) =>
        this.fetchSettingGroupByUIdGQL.fetch({ uid: uId }, { fetchPolicy: 'no-cache' }).pipe(
          map((response) =>
            fetchSettingGroup.success({
              uId,
              settingGroup: response.data.settingGroup,
            })
          ),
          catchError((error) => [fetchSettingGroup.failure({ uId, error })])
        )
      )
    )
  );

  fetchAllSettingsGQL = inject(FetchAllSettingsGQL);

  fetchAllSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchSettings.request),
      throttleTime(500),
      switchMap(() =>
        this.fetchAllSettingsGQL.fetch(undefined, { fetchPolicy: 'no-cache' }).pipe(
          map((response) =>
            fetchSettings.success({
              settings: response.data.groups.flatMap((group) => group.settings),
            })
          ),
          catchError((error) => [fetchSettings.failure({ error })])
        )
      )
    )
  );

  fetchSetting = inject(FetchSettingGQL);

  fetchSetting$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchSetting.request),
      mergeMap(({ groupUId, uId }) =>
        this.fetchSetting.fetch({ groupUid: groupUId, settingUid: uId }, { fetchPolicy: 'no-cache' }).pipe(
          map((response) =>
            fetchSetting.success({
              uId,
              setting: response.data.group?.settings?.[0],
            })
          ),
          catchError((error) => [fetchSetting.failure({ uId, error })])
        )
      )
    )
  );

  fetchValueMetadataGQL = inject(FetchValueMetadataGQL);

  fetchValueMetadata$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchValueMetadata.request),
      debounceTime(1000),
      switchMap(() =>
        this.fetchValueMetadataGQL.fetch(undefined, { fetchPolicy: 'no-cache' }).pipe(
          map((response) =>
            fetchValueMetadata.success({
              metadata: response.data.group?.flatMap(
                (group) => group.valueMetadata
              ),
            })
          ),
          catchError((error) => [fetchValueMetadata.failure({ error })])
        )
      )
    )
  );

  fetchSingleValueMetadata = inject(FetchSingleValueMetadataGQL);

  fetchSingleValueMetadata$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchSingleValueMetadata.request),
      mergeMap(({ groupUId, uId }) =>
        this.fetchSingleValueMetadata
          .fetch({ groupUid: groupUId, metadataUid: uId }, { fetchPolicy: 'no-cache' })
          .pipe(
            map((response) =>
              fetchSingleValueMetadata.success({
                uId,
                settingMetadata: response.data.group?.valueMetadata?.[0],
              })
            ),
            catchError((error) => [
              fetchSingleValueMetadata.failure({ uId, error }),
            ])
          )
      )
    )
  );

  fetchSettingByGroupUIdAndKey = inject(FetchSettingByGroupUIdAndKeyGQL);

  fetchSettingByGroupUIdAndKey$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchSettingByGroupUIdAndKey.request),
      switchMap(({ groupUId, key }) =>
        this.fetchSettingByGroupUIdAndKey
          .fetch({ groupUid: groupUId, settingKey: key }, { fetchPolicy: 'no-cache' })
          .pipe(
            map((response) => {
              return fetchSettingByGroupUIdAndKey.success({
                key,
                settings: response.data.group?.settings ?? [],
              });
            }),
            catchError((error) => {
              return of(fetchSettingByGroupUIdAndKey.failure({ key, error }));
            })
          )
      )
    )
  );

  ngrxOnInitEffects(): Action {
    return initKeyValueSetting();
  }
}

import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable({ providedIn: 'root' })
export class UserCommunicationService {
    public isUserUpdated :Observable<boolean> = new Observable();

    clear() {
        this.isUserUpdated = new Observable();
    }
}
import { Injectable, inject, signal } from '@angular/core';
import { OrganizationalUnitService as SwaggerOrganizationalUnitService } from '@swagger/humanresources';
import { createStore } from '@ngneat/elf';
import {
  selectActiveEntity,
  getActiveEntity,
  selectAllEntities,
  selectEntities,
  selectEntity,
  setActiveId,
  setEntities,
  withActiveId,
  withEntities,
  getEntity,
} from '@ngneat/elf-entities';
import {
  CommunicationDetailsDto,
  OperatingHourDto,
  OrganizationalUnitDto,
} from '@swagger/humanresources';
import { firstValueFrom, map, Observable, of, tap } from 'rxjs';
import { AbstractUnitService } from '../abstract-unit.service';
import { ContractsFormDto } from './create-ou.types';

export type OrganizationalUnitEntity = OrganizationalUnitDto & {
  uId: string;
  start?: Date;
  stop?: Date;
};

@Injectable({ providedIn: 'root' })
export class HrpOrganizationalUnitService extends AbstractUnitService {
  private store = createStore(
    { name: 'administraion-business-organisation-unit' },
    withEntities<OrganizationalUnitEntity, 'uId'>({
      idKey: 'uId',
    }),
    withActiveId()
  );

  $fetching = signal(false);

  private organizationalUnitService = inject(SwaggerOrganizationalUnitService);

  organizationalUnits$ = this.store.pipe(
    selectAllEntities(),
    tap((entities) => {
      if (!entities.length && !this.$fetching()) {
        this.fetchOrganizationalUnits().subscribe();
      }
    })
  );

  rootOrganizationalUnits$ = this.organizationalUnits$.pipe(
    map((entities) =>
      entities.filter((ou) => {
        if (!ou.parent?.uId) {
          return true;
        }
        if (!entities.some((e) => e.uId === ou.parent?.uId)) {
          return true;
        }

        return false;
      })
    )
  );

  activeOrganizationalUnit$ = this.store.pipe(
    selectActiveEntity(),
    map((ou) => {
      if (ou !== undefined) {
        const children = ou?.children?.map((c) => {
          const child = this.store.query(getEntity(c.uId));
          return { uId: c.uId, displayLabel: child?.name, type: child?.type };
        });

        const parent = this.store.query(getEntity(ou?.parent?.uId));
        ou.parent = {
          uId: parent?.uId,
          displayLabel: parent?.name,
        };
        ou.children = children;
        return ou;
      }
    })
  );

  getActiveUId() {
    return this.store.query(getActiveEntity())?.uId;
  }

  hasActiveOrganizationalUnit() {
    const activentity = this.store.query(getActiveEntity());
    return activentity !== undefined;
  }

  async resetStore() {
    sessionStorage.removeItem('ous');
    this.store.reset();
  }

  setActiveOrganizationalUnit(uId: string) {
    this.store.update(setActiveId(uId));
  }

  getOperatingHoursFormActiveOU() {
    return this.activeOrganizationalUnit$.pipe(
      map((ou) => {
        return ou?.operatingHours?.map((oh) => oh.data);
      })
    );
  }

  getContractDetailsFromActiveOU(): Observable<ContractsFormDto> {
    return this.activeOrganizationalUnit$.pipe(
      map((ou) => {
        const contractDto: ContractsFormDto = {
          holidayCalendar: ou?.holidayCalendar,
        };
        return contractDto;
      })
    );
  }

  getCommunicationDetailsFromActiveOU(): Observable<
    Record<string, CommunicationDetailsDto>
  > {
    return this.activeOrganizationalUnit$.pipe(
      map((ou) => {
        return {
          internal: {
            email: ou?.communicationDetailsInternal?.email,
            phone: ou?.communicationDetailsInternal?.phone,
            mobile: ou?.communicationDetailsInternal?.mobile,
            fax: ou?.communicationDetailsInternal?.fax,
          },
          public: {
            email: ou?.communicationDetailsPublic?.email,
            phone: ou?.communicationDetailsPublic?.phone,
            mobile: ou?.communicationDetailsPublic?.mobile,
            fax: ou?.communicationDetailsPublic?.fax,
          },
        };
      })
    );
  }

  getOrganizationalUnitByUId(uId: string) {
    return this.store.pipe(selectEntity(uId));
  }

  getOrganizationUnitsByUIds(uIds: string[]) {
    return this.store.pipe(
      selectEntities(),
      map((entities) => {
        const cleanedOus = uIds.map((uId) => uId.replace('*', '').trim());
        return cleanedOus.map((uId) => entities[uId]);
      })
    );
  }

  getOrganizationalUnitsForParentUId(parentUId: string) {
    return this.store.pipe(
      selectEntities(),
      map((entities) => {
        const ous: OrganizationalUnitDto[] = [];

        const parent = entities[parentUId];

        if (!parent) {
          return ous;
        }

        function traverse(ou: OrganizationalUnitEntity) {
          ous.push(ou);
          ou.children?.forEach((c) => {
            const child = entities[c.uId];
            if (child) {
              traverse(child);
            }
          });
        }

        traverse(parent);

        return ous;
      })
    );
  }

  getParentUIdFromUId(uId: string) {
    return this.store
      .pipe(selectEntity(uId))
      .pipe(map((ou) => ou?.parent?.uId));
  }

  updateOrganisationalUnit(
    uId: string,
    organisationalUnit: OrganizationalUnitDto
  ) {
    return this.organizationalUnitService
      .organizationalUnitUpdateOu({
        ouUId: uId,
        body: organisationalUnit,
      })
      .pipe(map((response) => response.result));
  }

  createOrganisationalUnit(organisationalUnit: OrganizationalUnitDto) {
    return this.organizationalUnitService
      .organizationalUnitCreateOu({
        body: organisationalUnit,
      })
      .pipe(map((response) => response.result));
  }

  moveChildren(sourceUid: string, targetUid: string) {
    return this.organizationalUnitService
      .organizationalUnitMoveChildren({
        body: {
          sourceParentUId: sourceUid,
          targetParentUId: targetUid,
        },
      })
      .pipe(map((response) => response.result));
  }

  fetchOrganizationalUnits() {
    this.$fetching.update(() => true);
    //check if ous are in session storage
    const storedOUs = sessionStorage.getItem('ous');

    if (storedOUs) {
      const parsedOUs = JSON.parse(storedOUs);

      this.store.update(setEntities(this.flattenUnits(parsedOUs)));
      this.$fetching.update(() => false);
      return of(parsedOUs);
    }

    return this.organizationalUnitService
      .organizationalUnitQueryOu({
        body: {},
      })
      .pipe(
        tap((response) => {
          this.store.update(setEntities(this.flattenUnits(response.result)));
          sessionStorage.setItem('ous', JSON.stringify(response.result));
          this.$fetching.update(() => false);
        })
      );
  }

  fetchOrganizationalUnitByParentUId(parentUId: string) {
    return this.organizationalUnitService
      .organizationalUnitGetOUs({
        parentUId,
      })
      .pipe(
        tap((response) => {
          this.store.update(setEntities(this.flattenUnits(response.result)));
        })
      );
  }

  createOperatingHour(ouUId: string, operatingHour: OperatingHourDto) {
    return this.organizationalUnitService
      .organizationalUnitCreateOperatingHour({
        ouUId: ouUId,
        body: operatingHour,
      })
      .pipe(map((response) => response.result));
  }

  updateOperatingHour(ouUId: string, operatingHour: OperatingHourDto) {
    return this.organizationalUnitService
      .organizationalUnitUpdateOperatingHour({
        ouUId: ouUId,
        operatingHourUId: operatingHour.uId,
        body: operatingHour,
      })
      .pipe(map((response) => response.result));
  }

  async hasSameParent(ous: OrganizationalUnitDto[]) {
    const hasSameParent$ = await this.organizationalUnits$.pipe(
      map((organisationalUnits) => {
        let childrens = organisationalUnits.filter((organisationalUnit) => {
          return ous.some((c) => c.uId === organisationalUnit.uId);
        });

        const parentUIds = childrens.map((c) => c.parent?.uId);
        return new Set(parentUIds).size === 1;
      })
    );

    return await firstValueFrom(hasSameParent$);
  }
}

import {
  AbstractControl,
  FormArray,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import moment from 'moment';

export function overlappingDateRangesValidatorDisability(
  formArray: FormArray,
  currentIndex: number
): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const disabilities = formArray?.controls;

    if (!disabilities) {
      return null;
    }

    const currentStart = moment(disabilities[currentIndex].get('start')?.value);
    const currentStop = disabilities[currentIndex].get('stop')?.value
      ? moment(disabilities[currentIndex].get('stop')?.value)
      : null;

    // Loop through all controls in the FormArray to find overlaps
    for (let i = 0; i < disabilities.length; i++) {
      if (i !== currentIndex) {
        const otherStart = moment(disabilities[i].get('start')?.value);
        const otherStop = disabilities[i].get('stop')?.value
          ? moment(disabilities[i].get('stop')?.value)
          : null;

        if (
          areRangesOverlapping(currentStart, currentStop, otherStart, otherStop)
        ) {
          return { overlappingDateRanges: true }; // Return error if overlap found
        }
      }
    }

    return null; // No overlap, return null
  };
}

// Helper function to check if two date ranges overlap
function areRangesOverlapping(
  start1: moment.Moment,
  end1: moment.Moment | null,
  start2: moment.Moment,
  end2: moment.Moment | null
): boolean {
  if (!end1) {
    return start1.isSameOrBefore(end2);
  }

  if (!end2) {
    return start2.isSameOrBefore(end1);
  }
  return start1.isSameOrBefore(end2) && start2.isSameOrBefore(end1);
}

import { inject, Injectable } from '@angular/core';
import {
  ContributorService,
  UserAccountService,
  UserDto,
} from '@swagger/humanresources';
import { map, Observable, throwError } from 'rxjs';
import { CreateUnassignedAccount, CreateUnassignedAccountSchema } from './accounts.schema';
import { UserService } from '@features/users';

@Injectable({ providedIn: 'root' })
export class AccountsService {
  contributorService = inject(ContributorService);
  userService = inject(UserService);
  userAccountService = inject(UserAccountService);

  getUnassignedUserAccounts$(): Observable<UserDto[]> {
    return this.userAccountService
      .userAccountGetUnassignedUsers()
      .pipe(map((response) => response.result));
  }

  createUnassignedUserAccount$(
    account: CreateUnassignedAccount
  ): Observable<UserDto> {

    const result = CreateUnassignedAccountSchema.safeParse(account);

    if (!result.success) {
      return throwError(() => result.error);
    }

    return this.userAccountService
      .userAccountCreateUnassignedUserAccount({
        body: result.data,
      })
      .pipe(map((response) => response.result));
  }
}

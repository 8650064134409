import { inject, Injectable, OnInit } from '@angular/core';
import {
  RoleDto,
  RoleService as SwaggerRoleService,
} from '@swagger/humanresources';
import { map, Observable } from 'rxjs';

/**
 * Service for role related operations
 */
@Injectable({ providedIn: 'root' })
export class RoleService implements OnInit {
  private swaggerRoleService = inject(SwaggerRoleService);

  /**
   * Fetches all roles from the server and stores them in the store
   *
   * @returns {Observable<RoleDto[]>} An observable that emits an array of RoleDto objects.
   */
  allRoles(): Observable<RoleDto[]> {
    return this.swaggerRoleService
      .roleQuery({ body: {} })
      .pipe(map((response) => response.result));
  }

  ngOnInit(): void {
      console.log('RoleService initialized');
  }
}

import { z } from 'zod';

export const QueryTokenDtoSchema = z.object({
  filter: z.record(z.string()).default({}),
  input: z.record(z.string()).default({}),
  orderBy: z.array(z.object({})).default([]),
});

export const GetUserSchema = z.object({
  userUId: z.string(),
  eagerLoading: z.number().default(2),
});

export type GetUser = z.infer<typeof GetUserSchema>;

export const QueryUsersSchema = QueryTokenDtoSchema;

export type QueryUsers = z.infer<typeof QueryUsersSchema>;

export const UpdateOuAndRoleSchema = z.object({
  userUId: z.string(),
  ouUids: z.array(z.string()),
  roleUid: z.string(),
});

export type UpdateOuAndRole = z.infer<typeof UpdateOuAndRoleSchema>;

export const SendInvitationEmailSchema = z.object({
  userUId: z.string(),
  email: z.string().email(),
});

export type SendInvitationEmail = z.infer<typeof SendInvitationEmailSchema>;

export const LockUserSchema = z.object({
  userUId: z.string(),
});

export type LockUser = z.infer<typeof LockUserSchema>;

export const UnlockUserSchema = z.object({
  userUId: z.string(),
});

export type UnlockUser = z.infer<typeof UnlockUserSchema>;

export const DeleteUserSchema = z.object({
  userUId: z.string(),
  deletionComment: z.string().default('Gelöscht über Admin-UI'),
});

export type DeleteUser = z.infer<typeof DeleteUserSchema>;

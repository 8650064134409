import { inject, Injectable } from '@angular/core';
import {
  QuerySettingsDto,
  UserAccountService as SwaggerUserAccountService,
  UserAccountInfoDto,
} from '@swagger/humanresources';
import * as Schema from './user.schema';
import { map, Observable, tap, throwError } from 'rxjs';
import { EventQueue } from '@core/events';
import { GetUserAccountInfoResult, QueryUserAccountInfosResult } from './user.events';

/**
 * Service for user related operations like create, update and delete user data
 */
@Injectable({ providedIn: 'root' })
export class UserService {
  private eventQueue = inject(EventQueue);
  private userAccountService = inject(SwaggerUserAccountService);

  /**
   * Retrieves a user based on the provided parameters.
   *
   * @param params - The parameters required to get the user, adhering to the `Schema.GetUser` schema.
   * @returns An `Observable` that emits a `UserAccountInfoDto` object.
   *
   * @throws Will throw an error if the provided parameters do not pass validation.
   */
  getUserAccountInfo(params: Schema.GetUser): Observable<UserAccountInfoDto> {
    const result = Schema.GetUserSchema.safeParse(params);

    if (!result.success) {
      return throwError(() => result.error);
    }

    const { userUId, eagerLoading } = result.data;
    

    return this.userAccountService
      .userAccountGetUser({ userUId, eagerLoading })
      .pipe(
        map((r) => r.result),
        tap((user) =>
          this.eventQueue.dispatch(new GetUserAccountInfoResult(user))
        )
      );
  }

  /**
   * Queries the user account information settings.
   *
   * This method uses the `tempService` to fetch the contributor schedules settings
   * and maps the result to return the `QuerySettingsDto`.
   *
   * @returns {Observable<QuerySettingsDto>} An observable that emits the user account information settings.
   */
  queryUserAccountInfoSettings(): Observable<QuerySettingsDto> {
    return this.userAccountService
      .userAccountQueryUserSettings({})
      .pipe(map((r) => r.result));
  }

  /**
   * Queries users based on the provided parameters.
   *
   * @param params - The parameters for querying users, adhering to the `Schema.QueryUsers` schema.
   * @returns An observable that emits an array of `UserDto` objects.
   *
   * @throws Will throw an error if the provided parameters do not match the `Schema.QueryUsers` schema.
   */
  queryUsersAccountInfos(
    params: Schema.QueryUsers
  ): Observable<UserAccountInfoDto[]> {
    const result = Schema.QueryUsersSchema.safeParse(params);

    if (!result.success) {
      return throwError(() => result.error);
    }

    return this.userAccountService
      .userAccountQueryUsers({
        body: result.data,
      })
      .pipe(map((r) => r.result), tap(users => this.eventQueue.dispatch(new QueryUserAccountInfosResult(users))));
  }

  /**
   * Updates the organizational units (OUs) and role of a user.
   *
   * @param param - The parameters required to update the user's OUs and role.
   * @returns An observable that emits the updated user data.
   *
   * The function performs the following steps:
   * 1. Validates the input parameters using the `Schema.UpdateOuAndRoleSchema`.
   * 2. If validation fails, it throws an error with the validation result.
   * 3. Extracts the user UID, OU UIDs, and role UID from the validated data.
   * 4. Calls the `userAccountUpdateUser` method of `userAccountService` to update the user's OUs and role.
   * 5. Maps the result to return the updated user data.
   */
  updateOuAndRole(
    param: Schema.UpdateOuAndRole
  ): Observable<UserAccountInfoDto> {
    const result = Schema.UpdateOuAndRoleSchema.safeParse(param);

    if (!result.success) {
      return throwError(() => result.error);
    }

    const { userUId, ouUids, roleUid } = result.data;

    return this.userAccountService
      .userAccountUpdateUser({
        userUId,
        body: {
          uId: userUId,
          ou: ouUids.map((ouUid) => ({ uId: ouUid })),
          roles: [{ uId: roleUid }],
        },
      })
      .pipe(map((r) => r.result));
  }

  /**
   * Sends an invitation email to a user.
   *
   * @param param - The parameters required to send the invitation email.
   * @returns An Observable that emits a boolean indicating the success of the operation.
   *
   * @throws Will throw an error if the provided parameters are invalid.
   */
  sendInvitationEmail(param: Schema.SendInvitationEmail): Observable<boolean> {
    const result = Schema.SendInvitationEmailSchema.safeParse(param);

    if (!result.success) {
      return throwError(() => result.error);
    }

    const { userUId, email } = result.data;

    return this.userAccountService
      .userAccountResendInvitation({
        userUId,
        body: { email, userUId },
      })
      .pipe(map((response) => response.result));
  }

  /**
   * Locks a user account based on the provided parameters.
   *
   * @param {Schema.LockUser} param - The parameters required to lock a user account.
   * @returns {Observable<boolean>} - An observable that emits a boolean indicating the success of the operation.
   *
   * @throws {ZodError} - Throws an error if the provided parameters are invalid.
   */
  lockUser(param: Schema.LockUser): Observable<boolean> {
    const result = Schema.LockUserSchema.safeParse(param);

    if (!result.success) {
      return throwError(() => result.error);
    }

    const { userUId } = result.data;

    return this.userAccountService
      .userAccountLockUser({ userUId })
      .pipe(map((res) => res.result));
  }

  /**
   * Unlocks a user account based on the provided parameters.
   *
   * @param param - The parameters required to unlock a user, adhering to the `Schema.UnlockUser` schema.
   * @returns An `Observable` that emits `true` if the user was successfully unlocked, or an error if the operation failed.
   */
  unlockUser(param: Schema.UnlockUser): Observable<boolean> {
    const result = Schema.UnlockUserSchema.safeParse(param);

    if (!result.success) {
      return throwError(() => result.error);
    }

    const { userUId } = result.data;

    return this.userAccountService
      .userAccountUnlockUser({ userUId })
      .pipe(map((res) => res.result));
  }

  /**
   * Deletes a user based on the provided parameters.
   *
   * @param param - The parameters required to delete the user, adhering to the `Schema.DeleteUser` schema.
   * @returns An observable that emits a boolean indicating the success of the deletion.
   */
  deleteUser(param: Schema.DeleteUser): Observable<boolean> {
    const result = Schema.DeleteUserSchema.safeParse(param);

    if (!result.success) {
      return throwError(() => result.error);
    }

    const { userUId, deletionComment } = result.data;

    return this.userAccountService
      .userAccountDelete({ userUId, body: JSON.stringify(deletionComment) })
      .pipe(map((res) => res.result));
  }
}

import { Injectable } from '@angular/core';
import {
  AffectedAccountTypeDto,
  QueryTokenDto,
  ScheduleItemTypeDto,
} from '@swagger/humanresources';
import { ScheduleItemTypeService } from '@swagger/humanresources';
import { map, Observable } from 'rxjs';
import { ScheduleItemTypeValidators } from './schedule-item-type.validators';
import { ScheduleItemTypeEntity } from '../pages/schedule-item-type-settings-page/schedule-item-type-settings-page.store';

@Injectable({ providedIn: 'root' })
export class HrpScheduleItemTypeService {
  /**
   * ScheduleItemType types / Art des Zeiteintrags abfragen.
   */
  getScheduleItemTypes({
    queryToken = {},
    eagerLoading = 1,
  }: {
    queryToken?: QueryTokenDto;
    eagerLoading?: number;
  }) {
    return this.scheduleItemType
      .scheduleItemTypeQuery({ body: queryToken, eagerLoading })
      .pipe(map((response) => response.result));
  }

  getScheduleItemTypeDetails({
    scheduleItemTypeUId,
    eagerLoading = 2,
  }: {
    scheduleItemTypeUId: string;
    eagerLoading?: number;
  }) {
    return this.scheduleItemType
      .scheduleItemTypeGet({
        scheduleItemTypeUId,
        eagerLoading,
      })
      .pipe(map((response) => response.result));
  }

  createScheduleItemType(scheduleItemType: ScheduleItemTypeDto) {
    try {
      ScheduleItemTypeValidators.validateScheduleItemTypeDtoAndAffectedAccountTypeDto(
        {
          scheduleItemType,
        }
      );

      return this.scheduleItemType
        .scheduleItemTypeCreate({
          body: scheduleItemType,
          eagerLoading: 2,
        })
        .pipe(map((response) => response.result));
    } catch (e) {
      throw new Error(e);
    }
  }

  updateScheduleItemType(scheduleItemType: ScheduleItemTypeDto) {
    try {
      ScheduleItemTypeValidators.validateScheduleItemTypeDtoAndAffectedAccountTypeDto(
        {
          scheduleItemType,
          update: true,
        }
      );

      return this.scheduleItemType
        .scheduleItemTypeUpdate({
          scheduleItemTypeUId: scheduleItemType.uId,
          body: scheduleItemType,
          eagerLoading: 2,
        })
        .pipe(map((response) => response.result));
    } catch (e) {
      throw new Error(e);
    }
  }

  // Changes ScheduleItemType EntityStatus to Active (1)
  changeStatusToActive$(
    scheduleItemTypeUId: string
  ): Observable<ScheduleItemTypeEntity> {
    return this.scheduleItemType
      .scheduleItemTypeActivate({
        scheduleItemTypeUId,
      })
      .pipe(
        map((response) => {
          return { ...response.result, uId: response.result.uId };
        })
      );
  }

  // Changes ScheduleItemType EntityStatus to Archive (8)
  changeStatusToArchived$(
    scheduleItemTypeUId: string
  ): Observable<ScheduleItemTypeEntity> {
    return this.scheduleItemType
      .scheduleItemTypeArchive({
        scheduleItemTypeUId,
      })
      .pipe(
        map((response) => {
          return { ...response.result, uId: response.result.uId };
        })
      );
  }

  updateAffectedAccountType({
    scheduleItemType,
    affectedAccountType,
  }: {
    scheduleItemType: ScheduleItemTypeDto;
    affectedAccountType: AffectedAccountTypeDto;
  }) {
    try {
      ScheduleItemTypeValidators.validateScheduleItemTypeDtoAndAffectedAccountTypeDto(
        {
          scheduleItemType,
          update: true,
        }
      );

      return this.scheduleItemType
        .scheduleItemTypeUpdateAffectedAccountType({
          scheduleItemTypeUId: scheduleItemType.uId,
          affectedAccountTypeUId: affectedAccountType.uId,
          body: affectedAccountType,
          eagerLoading: 2,
        })
        .pipe(map((response) => response.result));
    } catch (e) {
      throw new Error(e);
    }
  }

  createAffectedAccountType({
    scheduleItemType,
    affectedAccountType,
  }: {
    scheduleItemType: ScheduleItemTypeDto;
    affectedAccountType: AffectedAccountTypeDto;
  }) {
    try {
      ScheduleItemTypeValidators.validateScheduleItemTypeDtoAndAffectedAccountTypeDto(
        {
          scheduleItemType,
        }
      );

      return this.scheduleItemType
        .scheduleItemTypeCreateAffectedAccountType({
          scheduleItemTypeUId: scheduleItemType.uId,
          body: affectedAccountType,
          eagerLoading: 2,
        })
        .pipe(map((response) => response.result));
    } catch (e) {
      throw new Error(e);
    }
  }

  deleteAffectedAccountType({
    scheduleItemType,
    affectedAccountType,
    deletionComment,
  }: {
    scheduleItemType: ScheduleItemTypeDto;
    affectedAccountType: AffectedAccountTypeDto;
    deletionComment: string;
  }) {
    try {
      ScheduleItemTypeValidators.validateScheduleItemTypeDtoAndAffectedAccountTypeDto(
        {
          scheduleItemType,
          update: true,
        }
      );

      return this.scheduleItemType
        .scheduleItemTypeDeleteAffectedAccountType({
          scheduleItemTypeUId: scheduleItemType.uId,
          affectedAccountTypeUId: affectedAccountType.uId,
          deletionComment,
        })
        .pipe(map((response) => response.result));
    } catch (e) {
      throw new Error(e);
    }
  }

  constructor(private scheduleItemType: ScheduleItemTypeService) {}
}

import { z } from 'zod';


export const CreateUnassignedAccountSchema = z.object({
    invitation: z.boolean().default(false),
    oUs: z.array(z.string()).min(1),
    roles: z.array(z.string()).min(1),
    userName: z.string().or(z.string().email()),
    recipients: z.array(z.string()),
});

export type CreateUnassignedAccount = z.infer<typeof CreateUnassignedAccountSchema>;
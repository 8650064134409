import { Injectable, inject } from '@angular/core';
import {
  AddSettingGQL,
  EntityStatus,
  Setting,
  SettingInput,
  UpdateSettingGQL,
  DeleteSettingGQL,
} from '@graphql/hrp-settings';
import {
  Subject,
  catchError,
  firstValueFrom,
  map,
  of,
  tap,
} from 'rxjs';
import { SettingServiceTypes } from './setting.types';
import { SettingValidators } from './setting.validators';
import moment from 'moment';
import { KeyValueSettingStore } from '../store';
import { getClosestSettingAfterSetting, getClosestSettingBeforeSetting } from './setting.utils';
import { SessionService } from '@core/auth';
import { MAX_DATE } from '@shared/constants';

@Injectable({ providedIn: 'root' })
export class SettingService {

  private session = inject(SessionService);

  private store = inject(KeyValueSettingStore);

  allSettings$ = this.store.allSettings$;

  latestSettingForEachKeyForSettingGroupUId$ = (uId: string) =>
    this.store.latestSettingForEachKeyForSettingGroupUId$(uId);

  latestSettingForEachKeyForSettingGroupKey$ = (key: string) =>
    this.store.latestSettingForEachKeyForSettingGroupKey$(key);

  latestSettingForKey$ = (groupUId: string, key: string) =>
    this.store.latestSettingForKey$(groupUId, key);

  currentSetting$ = (groupUIdOrKey: string, key: string) =>
    this.store.currentSetting$(groupUIdOrKey, key);

  activeSettings$ = (groupUIdOrKey: string, date: Date) =>
    this.store.activeSettings$(groupUIdOrKey,  date);

  pendingSettings$ = (groupUId: string, key: string) =>
    this.store.pendingSettings$(groupUId, key);

  previousSettings$ = (groupUId: string, key: string) =>
    this.store.previousSettings$(groupUId, key);

  valueMetadataForGroup$ = (groupUId: string) =>
    this.store.valueMetadataForGroup$(groupUId);

  settingGroup$ = (groupUIdOrKey: string) => this.store.settingGroup$(groupUIdOrKey);

  loadingGroups$ = this.store.loadingGroups$;

  loadingSettings$ = this.store.loadingSettings$;

  loadingSettingKey$ = (key: string) => this.store.loadingSettingKey$(key);

  loadingMetadata$ = this.store.loadingMetadata$;

  settingByUId$ = (uId: string) => this.store.settingByUId$(uId);

  settingGroups$ = this.store.settingGroups$;

  private _events$ = new Subject<SettingServiceTypes.SettingEvent>();
  events$ = this._events$.asObservable();

  private addSettingGQL = inject(AddSettingGQL);
  private updateSettingGQL = inject(UpdateSettingGQL);
  private deleteSettingGQL = inject(DeleteSettingGQL);

  async addSetting(
    params: SettingServiceTypes.AddSetting
  ): Promise<SettingServiceTypes.AddSettingValueResult> {
    // Validierung ob settingGroupUId und add gesetzt sind
    SettingValidators.validateAddSetting(params);
    const { add, settingGroupUId } = params;
    // Validierung ob values richtig gesetzt sind
    // wird geprüft bevor eine Setting erstellt wird
    if (add.values?.length) {
      SettingValidators.validateAddSettingValues({
        settingUId: 'pre add validation',
        settingGroupUId: 'pre add validation',
        add: add.values,
      });
    }

    // Setting Daten für die Mutation
    const settingToCrerate: SettingInput = {
      parentUId: settingGroupUId,
      key: add.key,
      name: add.name,
      validFrom: add.validFrom,
      validTo: add.validTo,
      status: EntityStatus.Online,
      created: new Date().toISOString(),
      changed: new Date().toISOString(),
      version: 1,
      values:
        add.values?.map((v) => ({
          value: v.value,
          ref: v.ref,
          status: EntityStatus.Online,
          created: new Date().toISOString(),
          changed: new Date().toISOString(),
          version: 1,
        })) ?? [],
    };

    /**
     * 1. Suche die setting die vor dem zu erstellenden Setting gültig ist
     * 1.1. Wenn eine Setting gefunden wurde, dann wird das Enddatum des alten Settings auf das Startdatum des neuen Settings gesetzt
     * 2. Suche die setting die nach dem zu erstellenden Setting gültig ist
     * 2.1. Wenn eine Setting gefunden wurde, die nach der zu erstellenden Setting gültig ist, dann wird das Enddatum des neuen Settings auf das Startdatum des gefundenen
     *      Settings gesetzt und das Startdatum des gefundenen Settings auf das Enddatum des neuen Settings gesetzt
     */

    const settings = await firstValueFrom(
      this.store.settingsWithGroupUIdAndKeyKey$(settingGroupUId, add.key)
    );
    // Step 1.
    // Filter alle Settings die vor dem zu erstellenden Setting gültig sind
    let previousSettings = settings.filter((setting) =>
      moment(setting.validFrom).isBefore(settingToCrerate.validFrom)
    );
    // Suche die Setting mit dem höchsten Startdatum
    const previousSetting = previousSettings.reduce((current, setting) => {
      if (
        !current &&
        moment(setting.validFrom).isBefore(settingToCrerate.validFrom)
      ) {
        // Wenn es kein current gibt und das Startdatum des zu erstellenden Settings vor dem Startdatum des gefundenen Settings liegt
        // dann wird das gefundene Setting zurückgegeben
        return setting;
      } else if (
        current &&
        moment(setting.validFrom).isAfter(current.validFrom)
      ) {
        return setting;
      }
      return current;
    }, null);

    // Step 1.1
    // Wenn ein Setting gefunden wurde, dann wird das Enddatum des gefundenen Settings auf das Startdatum des zu erstellenden Settings gesetzt
    if (previousSetting) {
      const validTo = moment(settingToCrerate.validFrom).toISOString();

      await firstValueFrom(
        this.updateSettingGQL.mutate({
          input: {
            uId: previousSetting.uId,
            validTo,
            changed: new Date().toISOString(),
            status: moment(validTo).isBefore(new Date())
              ? EntityStatus.Archived
              : EntityStatus.Online,
          },
        })
      );

      this._events$.next({
        type: 'SettingUpdatedEvent',
        uId: previousSetting.uId,
      });
    }

    // Step 2
    // Filter alle Settings die nach dem zu erstellenden Setting gültig sind
    let nextSettings = settings.filter((setting) =>
      moment(setting.validFrom).isAfter(settingToCrerate.validFrom)
    );

    // Suche die Setting mit dem niedrigsten Startdatum
    const nextSetting = nextSettings.reduce((current, setting) => {
      if (
        !current &&
        moment(setting.validFrom).isAfter(settingToCrerate.validFrom)
      ) {
        // Wenn es kein current gibt und das Startdatum des zu erstellenden Settings nach dem Startdatum des gefundenen Settings liegt
        // dann wird das gefundene Setting zurückgegeben
        return setting;
      } else if (
        current &&
        moment(setting.validFrom).isBefore(current.validFrom)
      ) {
        return setting;
      }
      return current;
    }, null);

    // Step 2.1
    // Wenn ein Setting gefunden wurde, dann wird das Enddatum des zu erstellenden Settings auf das Startdatum des gefundenen Settings gesetzt
    // und das Startdatum des gefundenen Settings auf das Enddatum des zu erstellenden Settings gesetzt
    if (nextSetting) {
      const validTo = moment(nextSetting.validFrom).toISOString();
      settingToCrerate.validTo = validTo;
    }

    const res = await firstValueFrom(
      this.addSettingGQL.mutate({ input: settingToCrerate })
    );

    this._events$.next({
      type: 'SettingAddedEvent',
      uId: res.data.add,
    });

    this.store.fetchSettings();
    return res.data.add;
  }

  async fillDateGapWithSetting(args: {
    setting: string;
    gapTo: 'previous' | 'next';
  }) {
    if (!args.setting) {
      return;
    }

    let setting = await firstValueFrom(this.settingByUId$(args.setting));

    if (!setting) {
      return;
    }
    console.log('Setting before update', setting);

    setting = structuredClone(setting);

    let settings = await firstValueFrom(
      this.store.settingsWithGroupUIdAndKeyKey$(setting.parentUId, setting.key)
    );

    settings = settings.sort((a, b) => moment(a.validFrom).diff(b.validFrom));

    if (args.gapTo === 'previous') {
      settings = settings.filter((s) =>
        moment(s.validFrom).isBefore(setting.validFrom)
      );
      const previousSetting = settings[settings.length - 1];

      if (!previousSetting) {
        throw new Error('No previous setting found');
      }

      setting.validFrom = previousSetting.validTo;
    } else {
      settings = settings.filter((s) =>
        moment(s.validFrom).isAfter(setting.validFrom)
      );
      const nextSetting = settings[0];

      if (!nextSetting) {
        throw new Error('No next setting found');
      }

      setting.validTo = nextSetting.validFrom;
    }

    await firstValueFrom(
      this.updateSettingGQL.mutate({
        input: {
          uId: setting.uId,
          validFrom: setting.validFrom,
          validTo: setting.validTo,
          changed: new Date().toISOString(),
        },
      })
    );

    this.store.fetchSettings();
  }

  async deleteSetting(settingUId: string) {
    /**
     * 1. Suche nach der Setting die gelöscht werden soll
     * 1.1. Wenn die Setting nicht gefunden wurde, dann verlasse die Funktion
     * 1.2. Wenn die Setting gefunden wurde und die Setting bereits aktiviert ist oder ware, dann wird ein Fehler geworfen
     * 2. Lösche die Setting
     */

    const setting = await firstValueFrom(this.settingByUId$(settingUId));

    if (!setting) {
      return;
    }

    if (moment(setting.validFrom).isSameOrBefore(moment().startOf('day'))) {
      throw new Error(
        'Cannot delete a setting that is active or has already been active'
      );
    }

    this.store.removeSettingByUId(settingUId);

    try {
      await firstValueFrom(
        this.updateSettingGQL.mutate({
          input: { uId: settingUId, status: EntityStatus.Deleted },
        })
      );
    } catch (error) {
      console.error('Error deleting setting', error);
    }



    this.store.fetchSettings();
  }

  async updateSetting(params: SettingServiceTypes.UpdateSetting) {
    // Validierung ob settingGroupUId und add gesetzt sind
    SettingValidators.validateUpdateSetting(params);
    const { settingUId, update } = params;
    // Validierung ob values richtig gesetzt sind
    // wird geprüft bevor eine Setting erstellt wird
    if (update.values?.length) {
      SettingValidators.validateAddSettingValues({
        settingUId: 'pre update validation',
        settingGroupUId: 'pre update validation',
        add: update.values,
      });
    }

    /**
     * 1. Suche nach der Setting die aktualisiert werden soll
     * 1.1. Wenn die Setting nicht gefunden wurde, dann wird ein Fehler geworfen
     * 1.2. Wenn die Setting gefunden wurde und die Setting bereits aktiviert ist oder war, dann wird ein Fehler geworfen
     * 2. Prüfung ob die Werte die aktualisiert werden sollen in Ordnung sind
     * 2.1. Ist das Startdatum des zu aktualisierenden Settings vor dem Startdatum des vorherigen Settings, dann wird ein Fehler geworfen
     * 2.2. Ist das Startdatum des zu aktualisierenden Settings nach dem Startdatum des nachfolgenden Settings, dann wird ein Fehler geworfen
     */

    // 1. Suche nach der Setting die aktualisiert werden soll
    const current = await firstValueFrom(this.settingByUId$(settingUId));

    // 1.1. Wenn die Setting nicht gefunden wurde, dann wird ein Fehler geworfen
    if (!current) {
      throw new Error(`Setting with UId ${settingUId} not found`);
    }

    // 1.2. Wenn die Setting gefunden wurde und die Setting bereits aktiviert ist oder war, dann wird ein Fehler geworfen
    if(!this.session.isSuperAdministrator && moment(current.validFrom).isSameOrBefore(moment().startOf('day'))) { 
      throw new Error('Cannot update a setting that is active or has already been active');
    }

    const settingsWithGroupUIdAndKeyKey = await firstValueFrom(
      this.store.settingsWithGroupUIdAndKeyKey$(
        current.parentUId,
        current.key
      )
    );

    // 2. Prüfung ob die Werte die aktualisiert werden sollen in Ordnung sind

    // 2.1. Ist das Startdatum des zu aktualisierenden Settings vor dem Startdatum des vorherigen Settings, dann wird ein Fehler geworfen
  
    const closestPreviousSetting = getClosestSettingBeforeSetting(settingsWithGroupUIdAndKeyKey, current);

    if(closestPreviousSetting) {
      if(closestPreviousSetting.validTo && moment(closestPreviousSetting.validTo).isAfter(update.validFrom, 'day')) { 
        throw new Error('administration.setting.error.valid-from-overlap');
      }
    }

    // 2.2. Ist das Startdatum des zu aktualisierenden Settings nach dem Startdatum des nachfolgenden Settings, dann wird ein Fehler geworfen
    const closestNextSetting = getClosestSettingAfterSetting(settingsWithGroupUIdAndKeyKey, current);

    if(closestNextSetting) {
      if(update.validTo && moment(closestNextSetting.validFrom).isBefore(update.validTo, 'day')) { 
        throw new Error('administration.setting.error.valid-to-overlap');
      }
    }

    // 3. Update
    const updated: Setting = {
      ...structuredClone(current),
      name: update.name ?? current.name,
      validFrom: update.validFrom ?? current.validFrom,
      validTo: update.validTo ?? current.validTo,
      changed: new Date().toISOString(),
      changeset: (current.changeset ?? 1) + 1,
    };

    // Fix - Leerstring kann nicht gespeichert werden, wenn der Wert nicht gesetzt ist
    // wird die Lücke geschlossen und auf den start des nächsten Settings gesetzt oder das maximale Datum
    updated.validTo = update.validTo || closestNextSetting?.validFrom || MAX_DATE.toJSON();

    if(this.session.isSuperAdministrator) { 
      updated.key = update.key ?? current.key;
    }

    for (let value of update?.values) {
      const indexOfExistingValue = updated.values.findIndex(
        (v) => v.ref === value.ref
      );

      const existingValue = updated.values[indexOfExistingValue];
      if (existingValue) {
        updated.values[indexOfExistingValue] = {
          ...existingValue,
          value: value.value,
          changed: new Date().toISOString(),
          changeset: (existingValue.changeset ?? 1) + 1,
        };
      } else {
        updated.values.push({
          __typename: 'SettingValue',
          value: value.value,
          ref: value.ref,
          status: EntityStatus.Online,
          created: new Date().toISOString(),
          changed: new Date().toISOString(),
          version: 1,
        });
      }
    }

    // Serverfehler wenn beim updaten der Typ __typename mitgeschickt wird
    delete updated.__typename;
    for(let value of updated.values) { 
      delete value.__typename;
    }

    await firstValueFrom(
      this.updateSettingGQL.mutate({
        input: updated,
      })
    );

    this.store.fetchSetting(current.parentUId, current.uId);
    this._events$.next({
      type: 'SettingUpdatedEvent',
      uId: settingUId,
    });
  }

  archiveSetting(settingUId: string): Promise<boolean> {
    return firstValueFrom(
      this.updateSettingGQL
        .mutate({
          input: {
            uId: settingUId,
            status: EntityStatus.Archived,
            changed: new Date().toISOString(),
            validTo: new Date().toISOString(),
          },
        })
        .pipe(
          map(() => true),
          tap(() => this.store.fetchSettings()),
          catchError(() => of(false))
        )
    );
  }


}

import { Injectable } from '@angular/core';
import { EventBase, EventCtor } from './event';
import { filter, merge, Observable, Subject, take, throwError } from 'rxjs';
import { coerceArray } from '@angular/cdk/coercion';

/**
 * EventQueue is a service that allows listening for and dispatching events.
 * It supports wildcard patterns for event names, enabling flexible event handling.
 *
 * Wildcard examples:
 * - `*` (asterisk) - matches any character sequence
 * - `event-name*` - matches any event that starts with `event-name`
 * - `*event-name` - matches any event that ends with `event-name`
 * - `*event-name*` - matches any event that contains `event-name`
 *
 * @example
 * ```typescript
 * const eventQueue = new EventQueue();
 *
 * // Listen for any event
 * eventQueue.on('*');
 *
 * // Listen for a specific event
 * eventQueue.on('user-logged-in');
 *
 * // Dispatch an event
 * eventQueue.dispatch(new CustomEvent('user-logged-in'));
 * ```
 */
@Injectable({ providedIn: 'root' })
export class EventQueue {
  private _brocker = new Subject<EventBase>();

  get events() {
    return this._brocker.asObservable();
  }

  /**
   * Listen for an event
   * @param eventName The name of the event to listen for
   */
  on(type: string): Observable<EventBase>;
  on(types: string[]): Observable<EventBase>;
  on<T extends EventBase>(type: EventCtor<T>): Observable<T>;
  on<T1 extends EventBase, T2 extends EventBase>(
    types: [EventCtor<T1>, EventCtor<T2>]
  ): Observable<T1 | T2>;
  on<T1 extends EventBase, T2 extends EventBase, T3 extends EventBase>(
    types: [EventCtor<T1>, EventCtor<T2>, EventCtor<T3>]
  ): Observable<T1 | T2 | T3>;
  on<T1 extends EventBase, T2 extends EventBase, T3 extends EventBase, T4 extends EventBase>(
    types: [EventCtor<T1>, EventCtor<T2>, EventCtor<T3>, EventCtor<T4>]
  ): Observable<T1 | T2 | T3 | T4>;
  on(types: (string | EventCtor<EventBase>)[]): Observable<EventBase>;
  on(
    types:
      | string
      | string[]
      | EventCtor<EventBase>
      | EventCtor<EventBase>[]
      | (string | EventCtor<EventBase>)[]
  ): Observable<EventBase> {
    const normalizedTypes = coerceArray(types);

    if (normalizedTypes.length === 0) {
      return throwError(
        () => new Error('At least one event type must be provided')
      );
    }

    return merge(...normalizedTypes.map((type) => this._on(type)));
  }

  private _on(type: string | EventCtor<EventBase>): Observable<EventBase> {
    return this.events.pipe(
      filter((event) => {
        if (typeof type === 'string') {
          if (type === '*') {
            return true;
          }

          if (type === event.type) {
            return true;
          }

          if (type.includes('*')) {
            const regex = new RegExp(`^${type.replace(/\*/g, '.*')}$`);
            return regex.test(event.type);
          }

          return false;
        }

        return event instanceof type;
      })
    );
  }

  /**
   * Dispatch an event
   * @param event The event to dispatch
   */
  dispatch<T extends EventBase>(event: T) {
    this._brocker.next(event);
  }
}

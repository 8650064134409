import { computed, inject } from '@angular/core';
import {
  patchState,
  signalStore,
  withComputed,
  withMethods,
  withState,
} from '@ngrx/signals';
import { tapResponse } from '@ngrx/operators';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { UserAccountInfoDto } from '@swagger/humanresources';
import { AccountsService, CreateUnassignedAccount } from '../business/accounts';
import { injectOpenErrorDialog } from '@shared/error-dialog';
import { mergeMap, switchMap, tap } from 'rxjs';
import { UserService } from '@features/users';

type AccountsStore = {
  entities: Record<string, UserAccountInfoDto>;
  fetching: boolean;
};

const initialState: AccountsStore = {
  entities: {},
  fetching: false,
};

export const AccountStore = signalStore(
  { providedIn: 'root' },
  withState(initialState),
  withComputed((store) => ({
    accounts: computed(() => Object.values(store.entities())),
  })),
  withMethods((store, openErrorDialog = injectOpenErrorDialog()) => ({
    handleRetchUserAccountsResponse(payload: UserAccountInfoDto[]) {
      const entities: Record<string, UserAccountInfoDto> = {};
      for (const account of payload) {
        entities[account.uId] = account;
      }
      patchState(store, { entities });
    },
    handleCreateUserAccountResponse(payload: UserAccountInfoDto) {
      patchState(store, {
        entities: { ...store.entities, [payload.uId]: payload },
      });
    },
    handleRequestError(error: any) {
      openErrorDialog({ title: '', message: '', error });
    },
  })),
  withMethods((store, userService = inject(UserService), accountService = inject(AccountsService)) => ({
    fetchUserAccounts: rxMethod<void>(($) =>
      $.pipe(
        tap(() => patchState(store, { fetching: true })),
        switchMap(() =>
          userService
            .queryUsersAccountInfos({})
            .pipe(
              tapResponse(
                store.handleRetchUserAccountsResponse,
                store.handleRequestError,
                () => patchState(store, { fetching: false })
              )
            )
        )
      )
    ),
    createUserAccount: rxMethod<CreateUnassignedAccount>((create$) =>
      create$.pipe(
        mergeMap((create) =>
          accountService
            .createUnassignedUserAccount$(create)
            .pipe(
              tapResponse(
                store.handleCreateUserAccountResponse,
                store.handleRequestError
              )
            )
        )
      )
    ),
  }))
);
